import {lazy} from 'react';

// Hawkeye, Leasing, EIP, Recovery --- Dashboards
const HLERDashboardRoutes = {
  // --------------- URI'S: PATHNAMES --------------- //
  'dashboard-performance,dashboard-eip,dashboard-management': 'dashboard',
  'recovery-dash-recovery': 'recoveryDash',
  'summary-dash-recovery': 'summaryDash',
  'commitment-mapping-performance': 'commitmentMapping',
  'commitment-mapping-performance-new': 'commitmentMappingNew',
  'live-report-performance,live-report-eip': 'liveReport',
  'daily-report-performance,daily-report-eip': 'dailyReport',
  'weekly-report-performance,weekly-report-eip': 'weeklyReport',
  'dead-kms-performance': 'deadKMsReport',
  'dead-km-eip': 'deadKMsReportEip',
  'qualification-pending-performance': 'qualificationPending',
  'car-recovery-report-performance': 'carRecovery',
  'driver-hisaab-drive2earn-performance,driver-hisaab-eip': 'leasingDriverHisaab',
  'driver-hisaab-leasing-performance,leasing-driver-hisaab-eip,leasing-driver-hisaab-recovery': 'leasingDriverHisaab',
  'driver-hisaab-revshare-performance,revshare-driver-hisaab-recovery': 'revShareDriverHisaab',
  'partner-performance-performance,partner-performance-eip': 'partnerPerformance',
  'adjustment-hawkeye,adjustment-leasing': 'penaltyAdjustment',
  'adjustments-eip': 'eipAdjustment',
  'offline-recovery-leasing,offline-recovery-hawkeye': 'offlineRecovery',
  'hisaab-hawkeye,hisaab-leasing': 'hisaabSummary',
  'os-report-hawkeye,os-report-leasing': 'osReport',
  'team-stats1': 'teamStat',
  'team-stats-old': 'teamStatsMulti',
  'weekly-compare-report': 'weeklyCompareReport',
  'weekly-compare-report-eip': 'weeklyCompareEip',
  'phonepe-eip': 'phonePeEip',
  '2-hourly-report': 'twoHourly',
  'weekly-summary-report,weekly-summary-report-eip': 'weeklySummaryNew',
  'incentive-qualification-report': 'tripQualification',
  'driver-rating-summary-hawkeye,driver-rating-summary-leasing': 'driverRatingSummary',
  'dead-kms-summary-hawkeye,dead-kms-summary-leasing': 'deadkmSummary',
  'nd-count-report': 'ndCount',
  'car-parking-report-hawkeye,car-parking-report-leasing': 'carParking',
  'team-target-performance': 'teamTarget',
  'car-summary': 'carSummary',
  'car-allocation-performance': 'carAllocationReport',
  'car-team-allocation-performance': 'carTeamAllocation',
  'live-car-status-hawkeye,live-car-status-leasing': 'liveCarReport',
  'driver-profile-performance': 'driverProfile',
  'incentive-plan-performance': 'incentivePlan',
  'historical-data-performance,historical-data-recovery': 'historicalData',
  'documentation-charge': 'admin/documentationCharge',
  'security-deposit': 'admin/securityDeposit',
  'security-deposit-transactions-adjustments': 'admin/securityDepositTransactionsAdjustments',
  'car-allocation-eip': 'carAllocation',
  'reconciliation-report': 'reconciliationReport',
  'car-status-report-leasing': 'carStatusReport',
  'car-status-error-leasing': 'carStatusError',
  'car-model-report-leasing': 'carModelReport',
  'daily-dash-hawkeye': 'dailyDash',
  'helpcentre,helpcentre-eip': 'helpCenter',
  'car-profile': 'carProfile',
  'helpcenter-dashboard': 'helpcenterDashboard',
  'driver-blacklist,driver-blacklist-performance': 'driverBlacklist',
  'deposit-rules': 'depositRule',
  'driver-walkin': 'onboardDriver/driverWalkIn',
  appointment: 'appointments',
  'util-Report': 'utilReport',
  'appointment-schedule': 'appointmentSchedule',
};
// Management Dashboard
const ManagementDashboardRoutes = {
  // --------------- URI'S: PATHNAMES --------------- //
  'rev-share-performance-management': 'revSharePerformance',
  'rev-share-stats-management': 'revShareStats',
  'recovery-report-management': 'recoveryReport',
  'car-eligibility-report-management': 'carEligibilityReport',
  'leasing-stats-management': 'leasingStats',
  'leasing-performance-management': 'leasingPerformance',
  'leasing-recovery-report-management': 'leasingRecoveryDash',
  'weekly-vehicle-report-management': 'weeklyVehicleReport',
  'car-performance-till-date-management': 'carHistoryReport',
  'total-car-report-management': 'totalCarReport',
  'car-purchase-report-management': 'carPurchaseReport',
  'gps-report-location-wise-management': 'gpsLocationWiseReport',
  'gps-report-car-wise-management': 'gpsCarWiseReport',
  'average-utilisation-management': 'averageUtilisation',
  'location-stats-management': 'locationStats',
  'location-compare-report-management': 'locationCompareReport',
  'shpv-report-management': 'shpvReport',
  'shift-wise-driver-data-management': 'shiftWiseDriverData',
  'driver-shift-timing-management': 'driverShiftTimings',
  'driver-attrition-management': 'monthlyAttrition',
  'location-level-uber-stats-management': 'locationLevelUberStats',
  'ola-dash-management': 'olaDash',
  'recruitment-report-management': 'recruitmentReport',
  'weekly-recovery-report-management': 'weeklyRecoveryReport',
  'live-report-mgmt': 'liveReportMgmt',
  'eip-report-mgmt': 'eipReport',
};
// Audit Dashboard
const AuditDashboardRoutes = {
  // --------------- URI'S: PATHNAMES --------------- //
  overview: 'audit/visibilityDash/overview',
  'location-wise-rta-dash': 'audit/visibilityDash/locationWiseRTA',
  'car-status-report': 'audit/visibilityDash/carStatusReport',
  'check-in-audit': 'audit/checkIn/checkIn',
  'car-drop-audit': 'audit/checkIn/dropOff',
  'checkin-details-list-audit': 'audit/checkIn/checkInDetailsList',
  'list-audit': 'audit/checkIn/auditList',
  'drop-off-list-audit': 'audit/checkIn/dropOffList',
  'Parking-checkin-audit': 'audit/checkIn/parking',
  'technical-audit-list': 'audit/checkIn/technicalAuditList',
  'parking-car-reco': 'audit/parkingManagement/parkingCarReco',
  'check-out-audit': 'audit/checkOut/checkOut',
  'check-out-list-audit': 'audit/checkOut/checkOutList',
  'car-list-audit': 'audit/carList',
  'allocation-audit': 'audit/allocation/allocation',
  'allocation-list-audit': 'audit/allocation/allocationList',
  'parking-mgmt-audit': 'audit/parkingManagement/parking',
  'parking-complex-audit': 'audit/parkingManagement/parkingComplex',
  'parking-section-audit': 'audit/parkingManagement/parkingSection',
  'parking-bay-audit': 'audit/parkingManagement/parkingBay',
  'parking-transfer-audit': 'audit/parkingManagement/parkingTransfer',
  'hub-transfer': 'audit/parkingManagement/hubTransfer',
  'hub-transfer-list': 'audit/parkingManagement/hubTransferList',
  'transfer-audit': 'audit/carStatusTransfer/carStatusTransfer',
  'car-transfer-list-audit': 'audit/carStatusTransfer/carStatusTransferList',
  'tyre-audit': 'audit/tyre/tyre',
  'tyre-list-audit': 'audit/tyre/tyreList',
  'battery-audit': 'audit/battery/battery',
  'battery-list-audit': 'audit/battery/batteryList',
  'bulk-data-upload': 'audit/bulkDataUpload',
  'car-search': 'audit/carSearch',
  'appointment-audit': 'appointments',
  'appointment-schedule-audit': 'appointmentSchedule',
};
// Onboard Driver
const OnboardingDashboardRoutes = {
  // --------------- URI'S: PATHNAMES --------------- //
  drivers: 'onboardDriver/drivers',
  'driver-test': 'onboardDriver/driverTest',
  'driver-training': 'onboardDriver/driverTraining',
  'ready-for-allocation': 'onboardDriver/kuber',
  'driver-deposit': 'onboardDriver/securityDeposit',
  'contract-signed': 'onboardDriver/contract',
  'driver-funnel': 'onboardDriver/driverFunnel',
  'driver-documentation-fee': 'onboardDriver/documentationCharge',
  transaction_adjustments: 'onboardDriver/securityDepositTransactionsAdjustments',
  'onboarding-followup': 'onboardDriver/followUp',
  'missing-documents': 'onboardDriver/missingDocuments',
  'referral-campaign': 'onboardDriver/referralCampaign',
  'uber-id-status': 'onboardDriver/uberReport',
  'referral-list': 'onboardDriver/referralsList',
  'referrer-referee-view': 'onboardDriver/referrerOrRefereeDetails',
  'referral-report': 'onboardDriver/referralReport',
  'driver-walkin': 'onboardDriver/driverWalkIn',
};
// Jarvis
const JarvisDashboardRoutes = {
  // --------------- URI'S: PATHNAMES --------------- //
  'garage-uploads': 'garageUploads',
  'reports-download': 'reportsDownload',
  'car-details': 'carManagementReport',
  'payment-report': 'paymentReport',
  'crm-download': 'crmDownload',
  'db-tables-check': 'dbTablesCheck',
  'uber-api': 'uberAPI',
  'platform-tables-reco': 'platformTableReco',
  'upload-gps': 'gpsUpload',
  'upload-lead-mgmt': 'uploadLeadMgmt',
  'eip-permission': 'driverEIPTransfer',
  'user-additional-permission': 'userAdditionalTeamAccess',
  'leasing-uploads': 'leasingUploads',
  'car-unallocated-report': 'carUnallocatedReport',
  'download-penalty': 'penaltyReportDownload',
  'non-everest-vehicle-report': 'nonEverestVehicleReport',
  'car-transfer': 'carTransfer',
  'bank-transfer-report': 'positivePayout',
  'revshare-uploads': 'revShareUpload',
  'generate-payment-link': 'paymentLinkReport',
  'audit-team': 'auditTeam',
  'admin-garage-team': 'garageTeam',
  'dm-team': 'dmTeam',
  'update-driver-uuid': 'updateDriverUUID',
  documentation_download: 'documentationFeeReport',
  'security-deposit-download': 'securityDepositReport',
  'car-incentive': 'carIncentive',
  'driver-incentive-revshare': 'revShareDriverIncentives',
  'driver-incentive-leasing': 'leasingDriverIncentives',
  'deposit-rules': 'depositRule',
  'hisaab-reco-report': 'hisaabRecoReport',
};
// Garage Dashboard
const GarageDashboardRoutes = {
  // --------------- URI'S: PATHNAMES --------------- //
  'garage-chekin': 'garage/checkIn/checkIn',
  'garage-checkin-details-list': 'garage/checkIn/checkInDetailsList',
  'garage-checkin-parking': 'garage/checkIn/parking',
  'garage-drop-off-list': 'garage/checkIn/dropOffList',
  'garage-audit-list': 'garage/checkIn/auditList',
  'garage-team': 'garage/garageTeam',
  'garage-assign-ta': 'garage/assignTA',
  'garage-repair-assign': 'garage/garageRepairAssign',
  'garage-pre-test': 'garage/preTrial',
  'garage-post-test': 'garage/postTrial',
  'failed-post-trial-summary': 'garage/postTrialSummary',
  'garage-repair-jobs': 'garage/repairJobsView',
  'garage-technician-jobs': 'garage/technicianJobs',
  'garage-repair-spare-parts': 'garage/spareRequests',
  'garage-spare-request-issue': 'garage/sparePartsIssueParts',
  'garage-spare-parts-issused': 'garage/sparePartsIssuedList',
  'garage-allocation': 'garage/allocation/allocation',
  'garage-allocation-list': 'garage/allocation/allocationList',
  'garage-check-out': 'garage/checkOut/checkOut',
  'garage-check-out-list': 'garage/checkOut/checkOutList',
  'garage-parking': 'garage/parkingManagement/parking',
  'garage-parking-complex': 'garage/parkingManagement/parkingComplex',
  'garage-parking-section': 'garage/parkingManagement/parkingSection',
  'garage-parking-bay': 'garage/parkingManagement/parkingBay',
  'garage-parking-transfer': 'garage/parkingManagement/parkingTransfer',
  'garage-hub-transfer': 'garage/parkingManagement/hubTransfer',
  'garage-parking-car-reco': 'garage/parkingManagement/parkingCarReco',
  'appointment-garage': 'appointments',
  'appointment-schedule-garage': 'appointmentSchedule',
  'garage-hub-transfer-list': 'garage/parkingManagement/hubTransferList',
  'garage-raise-spare-parts-request': 'garage/technicianJobs',
  'garage-all-cars': 'garage/allCars',
  'garage-tyre-add': 'garage/tyre/tyre',
  'garage-tyre-list': 'garage/tyre/tyreList',
  'garage-battery-add': 'garage/battery/battery',
  'garage-battery-list': 'garage/battery/batteryList',
  'garage-bulk-data-upload': 'garage/bulkDataUpload',
  'garage-car-historical-data': 'garage/historicalData',
  'garage-repair-service-weekly': 'garage/repairServiceWeekly',
  'garage-in-progress-repair-service': 'garage/progressRepairService',
  'pna-summary': 'garage/pnaSummary',
};

// combining all Dashboards Routes
const routerObject = {
  ...HLERDashboardRoutes,
  ...ManagementDashboardRoutes,
  ...AuditDashboardRoutes,
  ...OnboardingDashboardRoutes,
  ...JarvisDashboardRoutes,
  ...GarageDashboardRoutes,
};

function createRoutes(permissions) {
  // CREATING APP RELATED ROUTES w.r.t selected dashboard
  const routerList = [];
  // console.log(permissions?.uri);
  (permissions?.data || []).forEach(page => {
    Object.entries(routerObject).forEach(([key, val]) => {
      if (key.split(',').includes(page.uri)) {
        const pagePath = val.split('/').slice(-1)[0];
        const homePath = val;

        if (homePath.includes('audit/') || homePath.includes('garage/')) {
          routerList.push({
            path: `/home/${homePath}`,
            uri: page.uri,
            key: page.uri,
            exact: true,
            label: page.name,
            Component: lazy(() => import(`pages/${homePath}`)),
          });
        } else {
          routerList.push({
            path: `/home/${homePath}`,
            uri: page.uri,
            key: page.uri,
            exact: true,
            label: page.name,
            Component: lazy(() => import(`pages/${pagePath}`)),
          });
        }
      }
    });
  });
  return routerList;
}

export default createRoutes;
