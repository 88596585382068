import {Spin} from 'antd';
import {useEffect} from 'react';
import {connect} from 'react-redux';
import {useLocation, Redirect, useHistory} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import moment from 'moment';

// layout imports
import PublicLayout from './public';
import AuthLayout from './auth';
import MainLayout from './main';

import {Dashboard, GarageRoles} from 'utility/dashboardEnum';

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  main: MainLayout,
};

export const garageDefaultRouteBasedOnRole = (history, role, dashboard) => {
  const dashboardParam = `dashboard_name=${dashboard}`;
  switch (role) {
    case GarageRoles.CHECKIN_AUDITOR:
      history.push(`/home/garage/checkIn/checkIn?${dashboardParam}`);
      break;
    case GarageRoles.FLOOR_MANAGER:
      history.push(`/home/garage/garageRepairAssign?${dashboardParam}`);
      break;
    case GarageRoles.GARAGE_MANAGER:
      history.push(`/home/garage/garageRepairAssign?${dashboardParam}`);
      break;
    case GarageRoles.SPARE_INVENTORY_CONTROLLER:
      history.push(`/home/garage/sparePartsIssueParts?${dashboardParam}`);
      break;
    case GarageRoles.TECHNICAL_AUDITOR:
      history.push(`/home/garage/preTrial?${dashboardParam}`);
      break;
    case GarageRoles.TECHNICIAN:
      history.push(`/home/garage/technicianJobs?${dashboardParam}`);
      break;
    default:
      history.push(`/home/garage/checkIn/checkIn?${dashboardParam}`);
  }
};

export const garageDefaultRouteBasedOnRoleUrl = (role, dashboard) => {
  let hrefUrl = '';
  const dashboardParam = `dashboard_name=${dashboard}`;
  switch (role) {
    case GarageRoles.CHECKIN_AUDITOR:
      hrefUrl = `/#/home/garage/checkIn/checkIn?${dashboardParam}`;
      break;
    case GarageRoles.FLOOR_MANAGER:
      hrefUrl = `/#/home/garage/garageRepairAssign?${dashboardParam}`;
      break;
    case GarageRoles.GARAGE_MANAGER:
      hrefUrl = `/#/home/garage/garageRepairAssign?${dashboardParam}`;
      break;
    case GarageRoles.SPARE_INVENTORY_CONTROLLER:
      hrefUrl = `/#/home/garage/sparePartsIssueParts?${dashboardParam}`;
      break;
    case GarageRoles.TECHNICAL_AUDITOR:
      hrefUrl = `/#/home/garage/preTrial?${dashboardParam}`;
      break;
    case GarageRoles.TECHNICIAN:
      hrefUrl = `/#/home/garage/technicianJobs?${dashboardParam}`;
      break;
    default:
      hrefUrl = `/#/home/garage/checkIn/checkIn?${dashboardParam}`;
  }
  return hrefUrl;
};

const getLayout = pathname => {
  const CONDITION = ['/visitors', '/verifyOTP', '/thankYouPage', '/helpAppDeleteAccount'].includes(pathname);

  if (pathname === '/') return 'public';
  // if (/^\/auth(?=\/|$)/i.test(pathname)) return 'auth';
  if ('/auth/sign-in' === pathname || CONDITION) return 'auth';
  return 'main';
};

function handleDefaultNavigation(history, dashboard, role) {
  const dashboardParam = `dashboard_name=${dashboard}`;

  switch (dashboard) {
    case Dashboard.AUDITDASHBOARD:
      history.push(`/home/audit/checkIn/checkIn?${dashboardParam}`);
      break;
    case Dashboard.RECOVERYDASHBOARD:
      history.push(`/home/recoveryDash?${dashboardParam}`);
      break;
    case Dashboard.ONBOARDDRIVER:
      history.push(`/home/onboardDriver?${dashboardParam}`);
      break;
    case Dashboard.JARVIS:
      history.push(`/home/paymentReport?${dashboardParam}`);
      break;
    case Dashboard.GARAGEDASHBOARD:
      garageDefaultRouteBasedOnRole(history, role, dashboard);
      break;
    default:
      history.push(`/home/dashboard?${dashboardParam}`);
  }
}

const SelectedLayout = ({isLoading, isUserAuthorized, dashboard, userRole, children}) => {
  const {pathname} = useLocation();
  const history = useHistory();

  const layout = getLayout(pathname);
  const isAuthLayout = layout === 'auth';
  const Container = Layouts[layout];

  useEffect(() => {
    // General default navigation after login acc to dashboard
    if (!isLoading && isAuthLayout && isUserAuthorized) {
      handleDefaultNavigation(history, dashboard, userRole?.uri);
    }
  }, [isAuthLayout, isUserAuthorized, dashboard, isLoading, userRole]);

  // show loader when user in check authorization process
  if (isLoading && !isAuthLayout && !isUserAuthorized) {
    return (
      <div className="h-screen flex justify-center items-center">
        <Spin spinning />
      </div>
    );
  }

  // // redirect to login page if current is not login page and user not authorized

  if (!isAuthLayout && !isUserAuthorized) {
    return <Redirect to="/auth/sign-in" />;
  }

  // in other case render previously set layout
  return <Container path={pathname}>{children}</Container>;
};

const Layout = ({dashboard, isLoading, isUserAuthorized, userRole, children}) => {
  useEffect(() => {
    moment.updateLocale('en', {
      week: {
        dow: 1, /// Date offset
      },
    });
  }, []);

  return (
    <>
      <Helmet titleTemplate="Everest Fleet | %s" />
      <SelectedLayout
        isLoading={isLoading}
        isUserAuthorized={isUserAuthorized}
        dashboard={dashboard}
        userRole={userRole}
      >
        {children}
      </SelectedLayout>
    </>
  );
};

const mapStateToProps = ({userReducer, garageReducer}) => ({
  isLoading: userReducer.loading,
  isUserAuthorized: userReducer.authorized,
  dashboard: userReducer.dashboard,
  userRole: garageReducer.userRole,
});

export default connect(mapStateToProps, null)(Layout);
