export const addParamsToUrl = ({baseUrl, params}) => {
  const url = new URL(baseUrl, 'http://dummy'); // Use a dummy base for URL object manipulation

  Object.keys(params).forEach(key => {
    const value = params[key];
    if (value !== undefined && value !== null) {
      url.searchParams.append(key, value);
    }
  });

  return `${url.pathname}${url.search}`;
};

export const addOptionalQueryParams = (url, params) => {
  let urlTemp = url;
  if (params.search) {
    Object.entries(params.search).forEach(([key, value]) => {
      if (urlTemp.endsWith('?')) {
        urlTemp = `${urlTemp}${key}=${value}`;
      } else {
        urlTemp = `${urlTemp}&${key}=${value}`;
      }
    });
  }
  if (params.sort) {
    if (urlTemp.endsWith('?')) {
      urlTemp = `${urlTemp}sort_key=${params.sort.sort_key}&sort_order=${params.sort.sort_order}`;
    } else {
      urlTemp = `${urlTemp}&sort_key=${params.sort.sort_key}&sort_order=${params.sort.sort_order}`;
    }
  }
  return urlTemp;
};
