export const Dashboard = Object.freeze({
  JARVIS: 'Admin Panel',
  HAWKEYE: 'Hawkeye Dashboard',
  ONBOARDDRIVER: 'Onboard Driver',
  MANAGEMENTDASHBOARD: 'Management Dashboard',
  AUDITDASHBOARD: 'Audit Dashboard',
  PERFORMANCEDASHBOARD: 'Performance Dashboard',
  RECOVERYDASHBOARD: 'Recovery Dashboard',
  EIPDASHBOARD: 'EIP Dashboard',
  GARAGEDASHBOARD: 'Garage Dashboard',
});

export const DashboardURI = Object.freeze({
  JARVIS: 'admin-jarvis',
  ONBOARDDRIVER: 'driver-onboarding',
  MANAGEMENTDASHBOARD: 'management-dashboard',
  AUDITDASHBOARD: 'audit-dashboard',
  PERFORMANCEDASHBOARD: 'leasing-dashboard',
  RECOVERYDASHBOARD: 'recovery-dashboard',
  EIPDASHBOARD: 'eip-dashboard',
  GARAGEDASHBOARD: 'garage-dashboard',
});

export const LogoutActions = Object.freeze({
  SIGNOUT: 'signout',
  SESSION_EXPIRED: 'sessionExpired',
  PASSWORD: 'password',
});

export const INVALID_TOKEN_ERROR = 'Invalid token.';

export const GarageRoles = Object.freeze({
  GARAGE_MANAGER: 'garage-manager',
  TECHNICIAN: 'technician',
  SPARE_INVENTORY_CONTROLLER: 'spare-inventory-controller',
  FLOOR_MANAGER: 'floor-manager',
  TECHNICAL_AUDITOR: 'technical-auditor',
  CHECKIN_AUDITOR: 'checkin-auditor',
});

export const DashTypes = Object.freeze({
  GARAGE: 'garage',
});
