import {Button, Popover, Drawer, Select} from 'antd';
import {MenuOutlined, QuestionOutlined} from '@ant-design/icons';
import {useHistory, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import {useEffect, useState} from 'react';
import Cookies from 'js-cookie';

import {userLogout, userLoad} from 'redux/user/actions';
import {store} from 'redux/store';
import actions from 'redux/user/actionTypes';
import {onFetchCityNamesApi} from 'services/axios';

import {useTeamContext} from 'context/teamIDContext';
import {useMenuContext} from 'context/menuContext';
import {useGuideContext} from 'context/guideContext';
import {useLocalStorage} from 'context/onboardStepsContext';

import useWindowSize from 'hooks/useResize';

import {Dashboard, LogoutActions} from 'utility/dashboardEnum';
import WeekCalender from './weekCalender';
import DayCalender from './dayCalender';
import Sidebar from '../sidebar';
import createSidebarMenu from '../sidebar/sidebarUtils';
import {getQueryParams, setQueryParams} from 'components/common/onboardTable/urlParams';
import {garageDefaultRouteBasedOnRole, garageDefaultRouteBasedOnRoleUrl} from 'layout';
import {useQuery} from 'utility/useQuery';

const helpButtonNone = [
  'Overview',
  'Car Status',
  'Check IN',
  'Drop-off',
  'CheckIn Details List',
  'Parking',
  'Audit List',
  'Drop-off List',
  'Car List',
  'Allocation',
  'Parking Transfer',
  'Parking Complex',
  'Parking Section',
  'Parking Bay',
  'Car Status Transfer',
  'Car Status Transfer List',
  'Check OUT',
  'Check OUT List',
  'Battery',
  'Battery List',
  'Tyre',
  'Tyre List',
  'Live Car Status',
  'Location Wise RTA Dash',
  'Allocation List',
  'Bulk Data Upload',
  'Documentation Charge Leasing',
  'Security Deposit Leasing',
  'Security Deposit Transactions/ Adjustments Leasing',
  'Documentation Charge Hawkey',
  'Security Deposit Hawkey',
  'Security Deposit Transactions/ Adjustments Hawkey',
];

const Header = ({logout, loadCurrentAccount, userRole}) => {
  const history = useHistory();
  const windowSize = useWindowSize();
  const {pathname} = useLocation();

  const {userReducer} = store.getState();
  const {name, team, partner, manager, city, loc, teamInfo, permissions, dashboard} = userReducer;
  const {userReducer: stateData} = store.getState();

  const {setTeamID, setPartnerID, setManagerID, setCityID, setLocID, setSelectedDashboard, setCityDropdown} =
    useTeamContext();
  const {menu, pageObject, setPageObject} = useMenuContext();
  const {filters} = useMenuContext();
  const searchParams = useQuery();

  // Get params from url
  const urlParam = getQueryParams(history.location);
  const dashboardName = searchParams.get('dashboard_name');

  const {setOnboarding} = useGuideContext();
  const {setDataInLocalStorage} = useLocalStorage();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isProfileOpen, setProfileOpen] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [cityValue, setCityValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [dashboardValue, setDashboardValue] = useState(dashboardName ? dashboardName : dashboard);

  const calender = ['THIS_WEEK', 'LAST_WEEK'].includes(pageObject.calendar_type);
  const noCalender = ['NO_CALENDAR'].includes(pageObject.calendar_type) || dashboard === 'Onboard Driver';

  useEffect(() => {
    setTeamID({teamIDValue: Number(team)});
    setPartnerID({partnerIDValue: Number(partner)});
    setManagerID({managerIDValue: Number(manager)});
    setCityID({cityIDValue: Number(city)});
    setLocID({locIDValue: Number(loc)});
    setCityDropdown({cityValue: Number(city?.[0])});
  }, [team, partner, loc, manager, city]);

  useEffect(() => {
    if (dashboardName) {
      setSelectedDashboard(dashboardName);
      setDashboardValue(dashboardName);
      store.dispatch({
        type: actions.SET_STATE,
        payload: {
          dashboard: dashboardName,
        },
      });
      loadCurrentAccount(); // load currentAccount on every dashboard change
      Cookies.set('dashboard', dashboardName);
    }
  }, [dashboardName]);

  useEffect(() => {
    const sideBarMenuTemp = createSidebarMenu(permissions, dashboardValue);

    const path = [];
    sideBarMenuTemp.forEach(row => {
      if (row.children) {
        row.children.forEach(innerRow => {
          if (innerRow.key === pathname) {
            path.push(innerRow);
          }
        });
      } else if (row.key === pathname) {
        path.push(row);
      }
    });
    if (path.length) {
      setPageObject(path[0]);
    }
  }, [permissions, dashboardValue, pathname]);

  useEffect(
    () => () => {
      setOnboarding(prev => ({
        ...prev,
        [menu]: false,
      }));
    },
    [menu]
  );

  const onSelectDashboard = dashboard => {
    setSelectedDashboard(dashboard);
    setDashboardValue(dashboard);
    if (dashboardValue !== dashboard) {
      store.dispatch({
        type: actions.SET_STATE,
        payload: {
          dashboard,
        },
      });
      loadCurrentAccount(); // load currentAccount on every dashboard change
      Cookies.set('dashboard', dashboard);
      const dashboardParam = `dashboard_name=${dashboard}`;

      if (dashboard === Dashboard.AUDITDASHBOARD) {
        history.push(`/home/audit/checkIn/checkIn?${dashboardParam}`);
      } else if (dashboard === Dashboard.RECOVERYDASHBOARD) {
        history.push(`/home/recoveryDash?${dashboardParam}`);
      } else if (dashboard === Dashboard.ONBOARDDRIVER) {
        history.push(`/home/onboardDriver?${dashboardParam}`);
      } else if (dashboard === Dashboard.JARVIS) {
        history.push('/home/paymentReport');
      } else if (dashboard === Dashboard.GARAGEDASHBOARD) {
        garageDefaultRouteBasedOnRole(history, userRole?.uri, dashboard);
      } else {
        history.push(`/home/dashboard?${dashboardParam}`);
      }
    }
  };

  const getUrl = item => {
    const dashboardParam = `dashboard_name=${item}`;
    if (item === Dashboard.AUDITDASHBOARD) {
      return `/#/home/audit/checkIn/checkIn?${dashboardParam}`;
    } else if (item === Dashboard.RECOVERYDASHBOARD) {
      return `/#/home/recoveryDash?${dashboardParam}`;
    } else if (item === Dashboard.ONBOARDDRIVER) {
      return `/#/home/onboardDriver?${dashboardParam}`;
    } else if (item === Dashboard.JARVIS) {
      return `/#/home/paymentReport?${dashboardParam}`;
    } else if (item === Dashboard.GARAGEDASHBOARD) {
      return garageDefaultRouteBasedOnRoleUrl(userRole?.uri, item);
    } else {
      return `/#/home/dashboard?${dashboardParam}`;
    }
  };

  const handleSignOut = () => {
    setLoading(true);
    setDataInLocalStorage({index: 0});
    logout(LogoutActions.SIGNOUT);
  };

  const getBgColor = item => {
    if (item === dashboardValue) return '#E6F7FF';
    return '#FFFFFF';
  };

  const showSidebar = () => {
    setSidebarOpen(true);
  };
  const onCloseSidebar = () => {
    setSidebarOpen(false);
  };

  const showProfile = () => {
    setProfileOpen(true);
  };
  const onCloseProfile = () => {
    setProfileOpen(false);
  };

  let laptop;
  if (windowSize.width < 992) {
    laptop = true;
  } else {
    laptop = false;
  }

  let tablet;
  if (windowSize.width < 768) {
    tablet = true;
  } else {
    tablet = false;
  }

  useEffect(() => {
    onFetchCityNamesApi()
      .then(res => {
        const cityTemp = res.data.data.sort((a, b) => a.name - b.name);
        setCityList([...cityTemp]);
        if (stateData && stateData?.city?.[0] > 0) {
          setCityValue(Number(stateData?.city[0]));
          store.dispatch({
            type: actions.SET_STATE,
            payload: {
              city: [Number(stateData?.city[0])],
              cityName: [stateData?.cityName[0]],
              companyAddress: [stateData?.companyAddress],
              companyName: [stateData?.companyName],
            },
          });
        } else {
          setCityValue(Number(cityTemp[0].id));
          store.dispatch({
            type: actions.SET_STATE,
            payload: {
              city: [Number(cityTemp[0]?.id)],
              cityName: [cityTemp[0]?.name],
              companyAddress: [cityTemp[0]?.company_address],
              companyName: [cityTemp[0]?.company_name],
            },
          });
        }
      })
      .catch(err => {
        console.log('err', err);
      });
  }, []);

  useEffect(() => {
    // Get and modify city_id from url
    setCityValue(urlParam.city_id ? urlParam.city_id : cityValue);
  }, [urlParam.city_id]);

  const helpButton = !helpButtonNone.includes(menu) ? (
    <Button
      type="primary"
      shape="circle"
      size="small"
      onClick={() =>
        setOnboarding(prev => ({
          ...prev,
          [menu]: true,
        }))
      }
      icon={<QuestionOutlined />}
    />
  ) : null;
  const onChangeLocation = cityOption => {
    store.dispatch({
      type: actions.SET_STATE,
      payload: {
        ...stateData,
        city: [cityOption?.value],
        cityName: [cityOption?.label],
        companyAddress: [cityOption?.companyAddress],
        companyName: [cityOption?.companyName],
        dashboard: dashboardName,
      },
    });
    Cookies.set('city', cityOption?.value);
    Cookies.set('cityName', cityOption?.label);
    Cookies.set('companyAddress', cityOption?.companyAddress);
    Cookies.set('companyName', cityOption?.companyName);
    setCityValue(cityOption?.value);

    // Set city_id in url
    setQueryParams(
      {...filters, city_id: cityOption?.value, page: 1, page_size: 10, dashboard_name: dashboardValue},
      history
    );
  };

  return (
    <div className="p-4 pb-2 flex flex-col">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center gap-4 flex-auto justify-between">
          {laptop ? (
            <>
              <Button type="secondary" icon={<MenuOutlined />} onClick={showSidebar} size="large" />
              <Drawer
                title={dashboardValue?.split(' ')[0]}
                placement="left"
                open={isSidebarOpen}
                onClose={onCloseSidebar}
                width={250}
                bodyStyle={{
                  padding: 0,
                }}
              >
                <Sidebar />
              </Drawer>
            </>
          ) : null}
          {tablet ? (
            <Popover
              placement="bottomLeft"
              content={<p className="text-[#333333] uppercase font-extrabold text-sm m-0">{teamInfo?.name}</p>}
              trigger="click"
              className="bg-[#013453]"
            />
          ) : (
            <>
              <p className="text-[#333333] uppercase font-extrabold text-sm m-0">
                {dashboardValue !== 'Jarvis' && teamInfo?.name}
              </p>
            </>
          )}
          {(dashboardValue === Dashboard.ONBOARDDRIVER || dashboardValue === Dashboard.JARVIS) && (
            <Select
              style={{
                minWidth: '160px',
                marginRight: '16px',
                marginTop: '4px',
              }}
              options={cityList.map(item => ({
                value: item?.id,
                companyAddress: item?.company_address,
                label: item?.name,
                companyName: item?.company_name,
              }))}
              value={cityValue}
              onChange={(_, cityOption) => onChangeLocation(cityOption)}
              placeholder="Select a Location..."
            />
          )}
        </div>
        {tablet ? (
          <>
            <div className="flex items-center gap-4">
              {noCalender ? null : <div>{calender ? <WeekCalender /> : <DayCalender />}</div>}
              <Button
                type="primary"
                icon={<img src="/assets/images/general/man.svg" alt="user" />}
                onClick={showProfile}
                size="large"
                className="p-0 bg-transparent border-0 focus:border-0"
              />
            </div>
            <Drawer
              title={<p className="font-medium text-xl m-0 pt-1">{name}</p>}
              placement="right"
              open={isProfileOpen}
              onClose={onCloseProfile}
              width={250}
              bodyStyle={{padding: '2rem 2.5rem'}}
            >
              <div className="flex flex-col">
                {Object.keys(permissions).map(item => (
                  <Button
                    key={`appheader-${item}`}
                    onClick={() => onSelectDashboard(item)}
                    type="button"
                    className="mb-1"
                    style={{backgroundColor: getBgColor(item)}}
                  >
                    <a href={getUrl(item)} target="_top" className="text-[#000000]">
                      {item}
                    </a>
                  </Button>
                ))}
                <hr className="my-2" />
                <Button onClick={handleSignOut} type="primary" loading={loading}>
                  Sign Out
                </Button>
              </div>
            </Drawer>
          </>
        ) : (
          <div className="flex gap-4 md:flex-row flex-col-reverse items-center">
            {noCalender ? null : <div>{calender ? <WeekCalender /> : <DayCalender />}</div>}
            {helpButton}
            <Select
              value={{
                value: dashboardValue,
                label: dashboardValue,
              }} // Removing anchor tag in value to handle redirection issue
              onChange={onSelectDashboard}
              options={Object.keys(permissions)?.map(item => ({
                value: item,
                label: (
                  <a href={getUrl(item)} target="_top" className="text-[#000000]">
                    {item}
                  </a>
                ),
              }))}
              style={{
                width: '11rem',
              }}
            />
            <Popover
              placement="bottomRight"
              title={name}
              content={
                <Button onClick={handleSignOut} className="w-36" type="primary" loading={loading}>
                  Sign Out
                </Button>
              }
              trigger="click"
            >
              <img src="/assets/images/general/man.svg" alt="user" width="40" className="cursor-pointer" />
            </Popover>
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  logout: action => dispatch(userLogout(action)),
  loadCurrentAccount: () => dispatch(userLoad()),
});
const mapStateToProps = ({userReducer, garageReducer}) => ({
  team: userReducer.team,
  userRole: garageReducer.userRole,
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
